<template>
 <div class="shadow overflow-hidden sm:rounded-lg m-4 content" v-if="!!Recivedata.documentNo">
    <div class="border-t border-gray-200">
      <dl>
        <div class="bg-white grid grid-cols-3 grid-row-2 gap-2">
            <div class="py-4 rounded-lg col-span-2 detail-order-number">หมายเลขคำสั่งซื้อ {{Recivedata.documentNo ?? '-'}}</div>
            <div class="text-center rounded-lg row-span-1 alert m-5 border">คำสั่งซื้อสำเร็จ</div>
        </div>
        <div class="bg-white grid grid-cols-2 grid-row-2 gap-2">
            <div class="py-4 rounded-lg col-span-2 m-2 hr" ><hr /></div>
            <div class="py-4 rounded-lg col-span-2 detail-order-date">งวดวันที่{{moment(Recivedata.periodDate).add(543, 'year').format('LL')}}</div>
            <div class="py-4 rounded-lg row-span-1 detail-order">วัน-เวลาที่สั่งซื้อ</div>
            <div class="text-center py-4 rounded-lg row-span-1 date-time">{{moment(Recivedata.createdAt).add(543, 'year').format('LLL')}}.น</div>
        </div>
      </dl>
    </div>
  </div>
  <div class="shadow overflow-hidden sm:rounded-lg m-4 content" v-else>
    <div class="border-t border-gray-200">
      <dl>
        <div class="bg-white grid grid-cols-3 grid-row-2 gap-2">
            <div class="py-4 rounded-lg col-span-2 detail-order-number">หมายเลขคำสั่งซื้อ -</div>
            <div class="text-center rounded-lg row-span-1 alert-unsuccess m-5 border">คำสั่งซื้อไม่สำเร็จ</div>
        </div>
        <div class="bg-white grid grid-cols-2 grid-row-2 gap-2">
            <div class="py-4 rounded-lg col-span-2 m-2 hr" ><hr /></div>
            <div class="py-4 rounded-lg col-span-2 detail-order-date">งวดวันที่ -</div>
            <div class="py-4 rounded-lg row-span-1 detail-order">วัน-เวลาที่สั่งซื้อ</div>
            <div class="text-center py-4 rounded-lg row-span-1 date-time">-</div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
// import { PaperClipIcon } from '@heroicons/vue/solid'
import moment from 'moment'
export default {
  name: 'OrderHeader',
  props: [
    'Recivedata'
  ],
  methods: {
    moment
  },
  mounted () {
    moment.locale('th')
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sarabun:wght@600&display=swap");
.content{
  margin-top: 12px;
  margin-left: 14px;
  margin-right: 13px;
  margin-bottom: 11px;
  border-radius: 10px;
}
.alert{
  font-size: 12px;
  border-color: #2EA47D;
  background-color: #E6FCF5;
  color: #2EA47D;
  width: 88px;
  height: 20px;
  border-radius: 30px;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
}
.alert-unsuccess{
  font-size: 12px;
  border-color: #e94242;
  background-color: #f3d4de;
  color: #d84c4c;
  width: 88px;
  height: 20px;
  border-radius: 30px;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
}
hr{
  color: #EBEBEB;
}
.detail-order {
  color: #3e3e3e;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
  padding-left: 12px;
  padding-bottom: 30.5px;
}
.detail-order-number {
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 16px;
  padding-top: 18px;
  padding-left: 15px;
}
.detail-order-date {
  color: #2ea47d;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 14px;
  padding-left: 16px;
  padding-top: 15px;
}
.date-time-buy{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 16px;
}
.date-time{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-bottom: 30.5px;
}
.buyer{
  color: #2ea47d;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 12px;
}
.name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'SemiBold';
  font-size: 16px;
}
.seller{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.buyer-name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
}
.seller-name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
}
.tel{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'SemiBold';
  font-size: 16px;
}
.tel-number{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 16px;
}
.alert1{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 18px;
}
.hr{
    padding: 0px 0px 0px 0px;
}
</style>
