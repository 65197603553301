import axiosHelpers from 'axios-helpers'
import axios from 'axios'
const axs = axiosHelpers(axios)
const env = process.env
axs.setBaseURL(env.VUE_APP_API_URL?.toString() || '')
axs.setHeader('Access-Control-Allow-Origin', '*')
// eslint-disable-next-line no-unused-vars
export default async function getData (url) {
  const data = await axios.$get('Order/GetByUrl/' + url)
  return data
}
