<template>
  <div class="shadow overflow-hidden sm:rounded-lg m-4 content" v-if="!!Recivedata.documentNo">
    <div class="border-t border-gray-200" v-for="item in Recivedata.orderItems" :key="item.id">
      <dl>
        <div class="bg-white grid grid-cols-2 grid-row-2 gap-2">
            <div class="py-4 rounded-lg col-span-2 title">ล็อตเตอรี่ที่สั่งซื้อ จำนวน{{Recivedata.pieceTotal ?? '-'}}ใบ</div>
            <div class=" rounded-lg col-span-1 image-overlay">
                <img class="lotto" src="../assets/image/background-LOT.png">
                <p>{{item.product.number}}</p>
            </div>
            <div class="rounded-lg col-span-2 m-2 hr"><hr></div>
        </div>
        <div class="bg-white grid grid-cols-4 grid-row-2 gap-2">
            <div class="rounded-lg col-span-1 title-head">หมายเลข</div>
            <div class="rounded-lg col-span-1 title-head">จำนวน</div>
            <div class="rounded-lg col-span-1 title-head">ราคา</div>
            <div class="rounded-lg col-span-1 title-head">รวม</div>
            <div class="rounded-lg col-span-1 detail">{{item.product.number}}</div>
            <div class="rounded-lg col-span-1 detail">{{item.qty}}</div>
            <div class="rounded-lg col-span-1 detail">{{(item.qty * item.product.price)}}</div>
            <div class="rounded-lg col-span-1 detail">{{(item.qty * item.product.price) + item.serviceCharge}}</div>
            <div class="rounded-lg col-span-4 m-2 hr"><hr></div>
            <div class="rounded-lg col-span-3 title-head">จำนวนทั้งหมด</div>
            <div class="rounded-lg col-span-1 title-head">{{Recivedata.pieceTotal ?? '-'}}ใบ</div>
            <div class="rounded-lg col-span-4 m-2 hr"><hr></div>
            <div class="rounded-lg col-span-3 title-head1">ราคาทั้งหมด</div>
            <div class="rounded-lg col-span-1 title-head1">฿{{Recivedata.grandTotal ?? '-'}}.00</div>
            <div class="rounded-lg col-span-4 detail-total">*ราคารวมค่าบริการแล้ว</div>
            <div class="rounded-lg col-span-4 m-2 hr-buttom"><hr></div>
        </div>
      </dl>
    </div>
  </div>
  <div class="shadow overflow-hidden sm:rounded-lg m-4 content" v-else>
    <div class="border-t border-gray-200">
      <dl>
        <div class="bg-white grid grid-cols-2 grid-row-2 gap-2">
            <div class="py-4 rounded-lg col-span-2 title">ล็อตเตอรี่ที่สั่งซื้อ จำนวน 0 ใบ</div>
            <div class=" rounded-lg col-span-1 image-overlay">
                <img class="lotto" src="../assets/image/background-LOT.png">
                <p>-</p>
            </div>
            <div class="rounded-lg col-span-2 m-2 hr"><hr></div>
        </div>
        <div class="bg-white grid grid-cols-4 grid-row-2 gap-2">
            <div class="rounded-lg col-span-1 title-head">หมายเลข</div>
            <div class="rounded-lg col-span-1 title-head">จำนวน</div>
            <div class="rounded-lg col-span-1 title-head">ราคา</div>
            <div class="rounded-lg col-span-1 title-head">รวม</div>
            <div class="rounded-lg col-span-1 detail">-</div>
            <div class="rounded-lg col-span-1 detail">-</div>
            <div class="rounded-lg col-span-1 detail">-</div>
            <div class="rounded-lg col-span-1 detail">-</div>
            <div class="rounded-lg col-span-4 m-2 hr"><hr></div>
            <div class="rounded-lg col-span-3 title-head">จำนวนทั้งหมด</div>
            <div class="rounded-lg col-span-1 title-head">0ใบ</div>
            <div class="rounded-lg col-span-4 m-2 hr"><hr></div>
            <div class="rounded-lg col-span-3 title-head1">ราคาทั้งหมด</div>
            <div class="rounded-lg col-span-1 title-head1">฿0.00</div>
            <div class="rounded-lg col-span-4 detail-total">*ราคารวมค่าบริการแล้ว</div>
            <div class="rounded-lg col-span-4 m-2 hr-buttom"><hr></div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderpictureMobile',
  mounted () {
    console.log(this.Recivedata.pieceTotal)
  },
  props: [
    'Recivedata'
  ]
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sarabun:wght@600&display=swap");
.content{
  border-radius: 10px;
}
.lotto{
    width: 136px;
    height: 68px;
    padding-left: 14px;
}
.title{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
  padding-top: 19.8px;
  padding-left: 18px;
  padding-bottom: 0px;
}
.title-head{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 14px;
  padding-left: 18px;
  padding-top: 0px;
}
.title-head1{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 14px;
  padding-left: 20.4px;
}
.detail{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 14px;
  padding-left: 18.6px;
}
.detail-total{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-left: 19.5px;
}
.hr{
    padding: 0px 0px 0px 0px;
}
.hr-buttom{
  padding-bottom: 44px;
}
.image-overlay{
  position: relative;
}
p{
  position: absolute;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  top: 2px;
  left: 75px;
}
</style>
