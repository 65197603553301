<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg m-4 content" v-if="!!Recivedata.documentNo">
    <div class="px-4 py-5 sm:px-6"  v-for="item in Recivedata.orderItems" :key="item.id">
      <div class="grid grid-cols-4 grid-row-1 gap-2">
      <div class="py-4 rounded-lg row-span-1 title">หมายเลข</div>
      <div class="py-4 rounded-lg row-span-1 title">จำนวน</div>
      <div class="py-4 rounded-lg row-span-1 title">ราคา</div>
      <div class="py-4 rounded-lg row-span-1 title">รวม</div>
      <div class="rounded-lg col-span-4"><hr/></div>
      </div>
      <div class="grid grid-cols-4 grid-row-1 gap-2">
      <div class="py-4 rounded-lg row-span-1 detail">{{item.product?.number ?? '-'}}</div>
      <div class="py-4 rounded-lg row-span-1 detail">{{item.qty}}</div>
      <div class="py-4 rounded-lg row-span-1 detail">{{(item.qty * item.product.price)}}</div>
      <div class="py-4 rounded-lg row-span-1 detail">{{(item.qty * item.product.price) + item.serviceCharge}}</div>
      <div class="rounded-lg col-span-4"><hr/></div>
      </div>
      <div class="grid grid-cols-5 grid-row-1 gap-2">
      <div class="py-4 rounded-lg col-span-2 title">จำนวนทั้งหมด</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 title">{{Recivedata.pieceTotal ?? '-'}}ใบ</div>
      <div class=" rounded-lg row-span-1 detail-price">ราคาทั้งหมด</div>
      <div class=" rounded-lg row-span-1"></div>
      <div class=" rounded-lg row-span-1"></div>
      <div class=" rounded-lg row-span-1"></div>
      <div class=" rounded-lg row-span-1 detail-price">฿{{Recivedata.grandTotal ?? '-'}}.00</div>
      <div class=" rounded-lg col-sapn-5 detail1">*รวมค่าบริการแล้ว</div>
      </div>
    </div>
  </div>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg m-4 content" v-else>
    <div class="px-4 py-5 sm:px-6">
      <div class="grid grid-cols-4 grid-row-1 gap-2">
      <div class="py-4 rounded-lg row-span-1 title">หมายเลข</div>
      <div class="py-4 rounded-lg row-span-1 title">จำนวน</div>
      <div class="py-4 rounded-lg row-span-1 title">ราคา</div>
      <div class="py-4 rounded-lg row-span-1 title">รวม</div>
      <div class="rounded-lg col-span-4"><hr/></div>
      </div>
      <div class="grid grid-cols-4 grid-row-1 gap-2">
      <div class="py-4 rounded-lg row-span-1 detail">-</div>
      <div class="py-4 rounded-lg row-span-1 detail">-</div>
      <div class="py-4 rounded-lg row-span-1 detail">-</div>
      <div class="py-4 rounded-lg row-span-1 detail">-</div>
      <div class="rounded-lg col-span-4"><hr/></div>
      </div>
      <div class="grid grid-cols-5 grid-row-1 gap-2">
      <div class="py-4 rounded-lg col-span-2 title">จำนวนทั้งหมด</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 title">0ใบ</div>
      <div class=" rounded-lg row-span-1 detail-price">ราคาทั้งหมด</div>
      <div class=" rounded-lg row-span-1"></div>
      <div class=" rounded-lg row-span-1"></div>
      <div class=" rounded-lg row-span-1"></div>
      <div class=" rounded-lg row-span-1 detail-price">฿0.00</div>
      <div class=" rounded-lg col-sapn-5 detail1">*รวมค่าบริการแล้ว</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetail',
  props: [
    'Recivedata'
  ]
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sarabun:wght@600&display=swap");
.content{
  margin-top: 20px;
  margin-left: 75px;
  margin-right: 10px;
}
.title{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.detail{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 18px;
}
.detail-price{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
  padding-left: 18px;
}
.detail1{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-left: 18px;
}
</style>
