<template>
  <Disclosure as="nav" class="nev-red" >
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <img class="block lg:hidden " id="image" src="../assets/image/LOGO@2x.png"/>
            <img class="hidden lg:block" id="image" src="../assets/image/LOGO@2x.png"/>
          </div>
        </div>
  </Disclosure>
</template>

<script>
import { Disclosure } from '@headlessui/vue'
// import { MenuIcon, XIcon } from '@heroicons/vue/outline'
const navigation = []
export default {
  components: {
    Disclosure
  },
  setup () {
    return {
      navigation
    }
  }
}
</script>

<style scoped>
.nev-red{
    background-color: #C72128;
    height: 143px;
}
#image{
    width: 171px;
    height: 127px;
}
</style>
