<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg m-4" id="content" v-if="!!Recivedata.documentNo">
    <div class="px-4 py-5 sm:px-6">
      <div class="bg-bule-200 grid grid-cols-6 grid-row-2 gap-2">
      <div class="py-4 rounded-lg row-span-1 detail-order">รายการคำสั่งซื้อ</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 detail-order-number">หมายเลขคำสั่งซื้อ{{Recivedata.documentNo ?? '-'}}</div>
     <div div class="text-center py-4 rounded-lg row-span-1">
        <div class="border px-4 py-3 rounded" role="alert" id="alert">
          <strong class="alert1">คำสั่งซื้อสำเร็จ</strong>
        </div>
      </div>
      <div class="py-4 rounded-lg row-span-1 detail-order-date">งวดวันที่{{moment(Recivedata.periodDate).add(543, 'year').format('LL')}}</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 date-time-buy">วัน - เวลาที่สั่งซื้อ </div>
      <div class="py-4 rounded-lg row-span-1 date-time">{{moment(Recivedata.createdAt).add(543, 'year').format('LLL')}}.น</div>
      <div class="py-4 rounded-lg col-span-6"><hr /></div>
    </div>
    <div class="grid grid-cols-10 grid-row-2 gap-2">
      <div class="py-4 rounded-lg row-span-1 buyer">ข้อมูลผู้ซื้อ</div>
      <div class="py-4 rounded-lg row-span-1 name">ชื่อ</div>
      <div class="py-4 rounded-lg col-span-2 buyer-name">{{Recivedata.customerFirstName ?? '-'}}&nbsp;&nbsp;{{Recivedata.customerLastName ?? '-'}}</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 seller">ข้อมูลผู้ขาย</div>
      <div class="py-4 rounded-lg row-span-1 name">ชื่อ</div>
      <div class="py-4 rounded-lg row-span-1 seller-name">{{Recivedata.retailer?.storeName ?? '-'}}</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 tel">เบอร์</div>
      <div class="py-4 rounded-lg row-span-1 tel-number">{{Recivedata.customerTel ?? '-'}}</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 tel">เบอร์</div>
      <div class="py-4 rounded-lg row-span-1 tel-number">{{Recivedata.retailer?.user?.phoneNumber ?? '-'}}</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
    </div>
    </div>
  </div>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg m-4" id="content" v-else>
    <div class="px-4 py-5 sm:px-6">
      <div class="bg-bule-200 grid grid-cols-6 grid-row-2 gap-2">
      <div class="py-4 rounded-lg row-span-1 detail-order">รายการคำสั่งซื้อ</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 detail-order-number">หมายเลขคำสั่งซื้อ-</div>
     <div div class="text-center py-4 rounded-lg row-span-1">
        <div class="border px-4 py-3 rounded" role="alert" id="alert">
          <strong class="alert-unsuccess">คำสั่งซื้อไม่สำเร็จ</strong>
        </div>
      </div>
      <div class="py-4 rounded-lg row-span-1 detail-order-date">งวดวันที่ - </div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 date-time-buy">วัน - เวลาที่สั่งซื้อ </div>
      <div class="py-4 rounded-lg row-span-1 date-time">-</div>
      <div class="py-4 rounded-lg col-span-6"><hr /></div>
    </div>
    <div class="grid grid-cols-10 grid-row-2 gap-2">
      <div class="py-4 rounded-lg row-span-1 buyer">ข้อมูลผู้ซื้อ</div>
      <div class="py-4 rounded-lg row-span-1 name">ชื่อ</div>
      <div class="py-4 rounded-lg col-span-2 buyer-name">-</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 seller">ข้อมูลผู้ขาย</div>
      <div class="py-4 rounded-lg row-span-1 name">ชื่อ</div>
      <div class="py-4 rounded-lg row-span-1 seller-name">-</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 tel">เบอร์</div>
      <div class="py-4 rounded-lg row-span-1 tel-number">-</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1 tel">เบอร์</div>
      <div class="py-4 rounded-lg row-span-1 tel-number">-</div>
      <div class="py-4 rounded-lg row-span-1"></div>
      <div class="py-4 rounded-lg row-span-1"></div>
    </div>
    </div>
  </div>
</template>

<script>
// import { PaperClipIcon } from '@heroicons/vue/solid'
import moment from 'moment'
export default {
  name: 'OrderHeader',
  props: [
    'Recivedata'
  ],
  methods: {
    moment
  },
  mounted () {
    moment.locale('th')
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sarabun:wght@600&display=swap");
#content{
  margin-top: 38px;
  margin-left: 75px;
  margin-right: 80px;
}
#alert{
  background-color: #E6FCF5;
  border-block-color: #2EA47D;
  color: #2EA47D;
  font-size: 18px;
  padding: 0px 0px 0px 0px ;
  width: 146px;
  text-align: center;
  margin-right: 0px;
}
hr{
  color: #EBEBEB;
}
.detail-order {
  color: #3e3e3e;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'SemiBold';
  font-size: 24px;
}
.detail-order-number {
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.detail-order-date {
  color: #2ea47d;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.date-time-buy{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 16px;
}
.date-time{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 16px;
}
.buyer{
  color: #2ea47d;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'SemiBold';
  font-size: 16px;
}
.seller{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.buyer-name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
}
.seller-name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
}
.tel{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'SemiBold';
  font-size: 16px;
}
.tel-number{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 16px;
}
.alert1{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 18px;
}
.alert-unsuccess{
  color: #f04a4a;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 18px;
}
</style>
