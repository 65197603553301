<template>
  <div class="shadow overflow-hidden sm:rounded-lg m-4 content">
    <div class="border-t border-gray-200"  >
    <dl>
        <div class="bg-white grid grid-cols-3 grid-row-2 gap-2">
            <div class="rounded-lg col-span-3 buyer">ข้อมูลผู้ซื้อ</div>
            <div class="rounded-lg row-span-1 name">ชื่อ</div>
            <div class="rounded-lg col-span-2 buyer-name">{{getData.customerFirstName ?? '-'}}&nbsp;&nbsp;{{getData.customerLastName ?? '-'}}</div>
            <div class="rounded-lg col-span-1 tel">เบอร์โทร</div>
            <div class="rounded-lg col-span-2 tel-number">{{getData.customerTel ?? '-'}}</div>
            <div class="rounded-lg col-span-3 seller">ข้อมูลผู้ขาย</div>
            <div class="rounded-lg row-span-1 name">ชื่อ</div>
            <div class="rounded-lg col-span-2 seller-name">{{getData.retailer?.storeName ?? '-'}}</div>
            <div class="rounded-lg col-span-1 tel-sell">เบอร์โทร</div>
            <div class="rounded-lg col-span-2 tel-number-sell">{{getData.retailer?.user?.phoneNumber ?? '-'}}</div>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderdetailMobile',
  props: [
    'getData'
  ]
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sarabun:wght@600&display=swap");
.buyer{
  color: #2ea47d;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 14px;
  padding-left: 14px;
  padding-top: 14.9px;
}
.name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regilar';
  font-size: 14px;
  padding-left: 15px;
  padding-top: 13.8px;
}
.seller{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 14px;
  padding-left: 14px;
  padding-top: 30.5px;
}
.buyer-name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-top: 13.8px;
}
.seller-name{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-top: 13.8px;
}
.tel{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
  padding-left: 17px;
  padding-top: 13px;
}
.tel-sell{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 14px;
  padding-left: 17px;
  padding-top: 13px;
  padding-bottom: 28.6px;
}
.tel-number{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-top: 13px;
}
.tel-number-sell{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
  padding-top: 13px;
  padding-bottom: 28.6px;
}
.content{
  border-radius: 10px;
}
.title{
  color: #525252;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.detail{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 18px;
}
.detail-price{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.detail1{
  color: #959595;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 12px;
}
</style>
