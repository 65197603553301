<template>
<div class="hidden xl:block">
    <NavBar/>
  <div class="columns-1">
  <OrderHeader :Recivedata="orderData"/>
  </div>
  <div class="grid grid-cols-2 grid-row-1 gap-2">
    <OrderDetail :Recivedata="orderData"/>
    <OrderPicture :Recivedata="orderData"/>
  </div>
</div>
<div class="block xl:hidden">
  <NavbarMobile/>
  <div class="columns-1">
    <OrderheaderMobile :Recivedata="orderData"/>
  </div>
  <div class="columns-1">
    <OrderdetailMobile :getData="orderData"/>
  </div>
  <div class="columns-1">
    <OrderpictureMobile :Recivedata="orderData"/>
  </div>
</div>

</template>

<script>
// var GetOrder = 'GetOrderByToken.json';
// import OrderPage from './components/OrderPage.vue'
import NavBar from './components/NavBar.vue'
import OrderHeader from './components/OrderHeader.vue'
import OrderDetail from './components/OrderDetial.vue'
import OrderPicture from './components/OrderPicture.vue'
import NavbarMobile from './components/NavbarMobile.vue'
import OrderheaderMobile from './components/OrderheaderMobile.vue'
import OrderdetailMobile from './components/OrderdetailMobile.vue'
import OrderpictureMobile from './components/OrderpictureMobile.vue'
import getData from './services/api/ApiService'
import './assets/css/tailwind.css'
export default {
  name: 'App',
  data: () => {
    return {
      orderData: {}
    }
  },
  mounted () {
    if (location.pathname.toLocaleUpperCase().substring(1) !== '') {
      getData(location.pathname.toLocaleUpperCase().substring(1)).then((res) => {
        // console.log(res)
        this.orderData = res
        // console.log(this.orderData)
      })
    }
  },
  components: {
    NavBar,
    OrderHeader,
    OrderDetail,
    OrderPicture,
    NavbarMobile,
    OrderheaderMobile,
    OrderdetailMobile,
    OrderpictureMobile
  }
}
</script>

<style>
body{
    background-color: #F6F6F6;
}
</style>
