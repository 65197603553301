<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg m-4 content" v-if="!!Recivedata.documentNo">
    <div class="px-4 py-5 sm:px-6" v-for="item in Recivedata.orderItems" :key="item.id">
      <div class="grid grid-cols-4 grid-row-1 gap-2">
      <div class="py-4 rounded-lg row-span-1 title">ล็อตเตอรี่ที่สั่งซื้อ</div>
      <div class="py-4 rounded-lg row-span-1 title">จำนวน {{item.qty ?? '-'}} ใบ</div>
      </div>
      <div class="grid grid-cols-3 grid-row-1 gap-2 image-overlay">
          <img class="lotto m-4" src="../assets/image/background-LOT.png">
          <p>{{item.product.number ?? '-'}}</p>
      </div>
    </div>
  </div>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg m-4 content" v-else>
    <div class="px-4 py-5 sm:px-6">
      <div class="grid grid-cols-4 grid-row-1 gap-2">
      <div class="py-4 rounded-lg row-span-1 title">ล็อตเตอรี่ที่สั่งซื้อ</div>
      <div class="py-4 rounded-lg row-span-1 title">จำนวน 0 ใบ</div>
      </div>
      <div class="grid grid-cols-3 grid-row-1 gap-2 image-overlay">
          <img class="lotto m-4" src="../assets/image/background-LOT.png">
          <p>-</p>
      </div>
    </div>
  </div>
</template>

<script>
// import { PaperClipIcon } from '@heroicons/vue/solid'
// import LotTo from './LotTo.vue'
export default {
  name: 'OrderPicture',
  props: [
    'Recivedata'
  ]
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Sarabun:wght@600&display=swap");
.content{
  margin-top: 20px;
  margin-right: 80px;
}
.lotto{
    width: 200px;
    height: 100px;
}
.title{
  color: #2EA47D;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Medium';
  font-size: 18px;
}
.image-overlay{
  position: relative;
}
p{
  position: absolute;
  font-family: 'Sarabun', sans-serif;
  font-weight: 'Regular';
  font-size: 18px;
  top: 17px;
  left: 120px;
}
</style>
